<template>
    <v-card class="mt-5">
        <AppVCardTitle label="Sécurité" />

        <AppCheckboxWithButton v-model="value.hasScamLadder" label="Echelle harnachée *" image_url="/img/implementedForms/62b97ed722d02/scamLadder.png" :required="true"/>

        <v-card-text>
            <v-checkbox v-model="value.hasHarnessWithLifeLine" class="mb-n4 mt-n4" label="Baudrier avec ligne de vie *" :rules="[v => v]"/>
        </v-card-text>

        <v-card-text>
            <v-checkbox v-model="value.hasPersonalProtectiveEquipment" class="mt-n4" label="EPI : Chaussures de sécurité, casque, lunettes, gants et vêtements à bandes réfléchissantes *" :rules="[v => v]"/>
        </v-card-text>
    </v-card>
</template>

<script>
import AppCheckboxWithButton from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/CheckboxWithButton";
import AppVCardTitle from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/VCardTitle";

export default {
    name: "PreventivePlan",
    components: {
        AppCheckboxWithButton,
        AppVCardTitle,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
};
</script>