<template>
    <v-form id="oleo100-curative-form" ref="form">
        <p class="mt-5">Vérifier et cocher dans le cas échéant les points ci-dessous.</p>

        <PreventivePlan v-model="preventivePlan" :saveEvent="save_event" />
        <Security v-model="security" />
        <GlobalView v-model="globalView" />
        <Manhole v-model="manhole" />
        <VariousVisualControls v-model="variousVisualControls" />
        <ControlOfTheElectricalCabinet v-model="controlOfTheElectricalCabinet" />
        <ControlOfTheDistributor v-model="controlOfTheDistributor" />

        <v-card class="mt-5">
            <AppSignature v-model="provider_signature" label="Signature du prestataire" :saveEvent="save_event" />
        </v-card>

        <v-btn class="mt-3 maintenance-detail-actions" height="40px" color="primary" @click="validate">
            {{ $t("maintenance_detail.generate_intervention_report") }}
        </v-btn>
    </v-form>
</template>

<script>
import AppSignature from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/Signature";
import PreventivePlan from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/Cards/PreventivePlan";
import Security from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/Cards/Security";
import GlobalView from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/Cards/GlobalView";
import Manhole from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/Cards/Manhole";
import VariousVisualControls from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/Cards/VariousVisualControls";
import ControlOfTheElectricalCabinet from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/Cards/ControlOfTheElectricalCabinet";
import ControlOfTheDistributor from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/Cards/ControlOfTheDistributor";
import MaintenanceRepository from "@/repositories/MaintenanceRepository";
import { routes } from "@/models/Pages";

export default {
    name: "Oleo100Preventive",
    components: {
        AppSignature,
        PreventivePlan,
        Security,
        GlobalView,
        Manhole,
        VariousVisualControls,
        ControlOfTheElectricalCabinet,
        ControlOfTheDistributor,
    },
    data() {
        return {
            preventivePlan: {
                hasPreventionPlan: "false",
                viewPreventionPlan: false,
                presenceOfTheCustomer: false,
                clientSignature: "",
                preventionPlan: null,
                comment: null,
            },
            security: {
                hasScamLadder: false,
                hasHarnessWithLifeLine: false,
                hasPersonalProtectiveEquipment: false,
            },
            globalView: {
                tank: null,
                distributor: null,
            },
            manhole: {
                lastBattery: 0,
                changeBattery: false,
                activateSensorAfterChange: false,
                lastMeasureCheck: {
                    value: -1,
                    expected: 0,
                    valid: true,
                },
                offsetCorrection: {
                    checked: "true",
                    comment: "",
                    gauging: null,
                },
                localDisplayState: {
                    checked: "true",
                    comment: "",
                    file: null,
                    height: null,
                },
                controlOfTheLeakDetector: false,
                leakDetectorCompliant: "true",
                needsComplementAntifreeze: "false",
                antifreezeVolume: "1",
                controlOfWeldsAndJoints: {
                    checked: false,
                    compliant: "true",
                    comment: "",
                    file: null,
                },
                device: {
                    nb_releve_par_jour: null,
                },
                changeDevice: false,
            },
            variousVisualControls: {
                floorChecks: {
                    checked: false,
                    compliant: "true",
                    comment: "",
                    file: null,
                },
                tankConditions: {
                    checked: false,
                    compliant: "true",
                    comment: "",
                    file: null,
                },
                pipingChecking: {
                    checked: false,
                    compliant: "true",
                    comment: "",
                    file: null,
                },
                regulatoryPlates: {
                    checked: false,
                    tippingIdentification: "true",
                    fourdataQRCode: "true",
                    pastPoster: false,
                },
            },
            controlOfTheElectricalCabinet: {
                controlTriggeringOfSafety: {
                    checked: false,
                    differential: "true",
                    emergencyStop: "true",
                },
                verificationOfElectricalEquipment: {
                    checked: false,
                    compliant: "true",
                    comment: "",
                    file: null,
                },
                checkingForLeaks: {
                    checked: false,
                    compliant: "true",
                    comment: "",
                    file: null,
                },
                electricalCableControl: {
                    checked: false,
                    compliant: "true",
                    comment: "",
                    file: null,
                },
                tighteningTheConnections: {
                    checked: false,
                    compliant: "true",
                    comment: "",
                    file: null,
                },
                controlTriggeringOfSafetyComment: "",
                controlTriggeringOfSafetyFile: null,
            },
            controlOfTheDistributor: {
                gunConditionCheck: {
                    checked: false,
                    compliant: "true",
                    comment: "",
                    file: null,
                },
                flexibleCheck: {
                    checked: false,
                    compliant: "true",
                    comment: "",
                    validityDate: "",
                    file: null,
                },
                filterReplacement: false,
                cartridgeFilterControl: {
                    checked: false,
                    cartridgeFilter: {
                        checked: "true",
                        comment: "",
                        file: null,
                    },
                    filterStock: {
                        checked: false,
                        hasFilterStock: "true",
                        inStock: 0,
                    },
                },
                filterBypassOpeningControl: false,
                isValveSealed: "true",
                valvePicture: null,
                switchOffMotor: false,
                tensionControl: false,
                resumptionOfTension: "false",
                changingBelt: "false",
                cleaning: false,
            },
            save_event: false,
            provider_signature: "",
        };
    },
    methods: {
        async validate () {
            const  valid  = await this.$refs.form.validate()

            if (valid){
                this.sendRepport();
            }
            else {
                this.$store.dispatch("snackbar/show", {
                    snackbar_text: 'Les champs suivie d\'une "*" sont obligatoires',
                    snackbar_type: "error",
                });
            }
        },
        async sendRepport() {
            this.save_event = true;
            let data = new FormData();
            data.append("json_form", JSON.stringify(this.formatData()));
            data.append("global_data", JSON.stringify(await this.globalData()));
            data.append("preventionPlan", this.preventivePlan.preventionPlan);

            this.$store.dispatch("ajax/in_recovery", { action: "Envoie du rapport" }, { root: true });
            MaintenanceRepository.validate(data).then((response) => {
                this.$store.dispatch("ajax/is_available", {}, { root: true });
                if (response.status === 200) {
                    this.report = null;

                    this.$store.dispatch("maintenance/load");
                    if (this.$store.getters["authentication/access_maintenance_list"]) {
                        this.$store.dispatch("maintenance_list/load");
                    }

                    this.$store.dispatch("snackbar/show", {
                        snackbar_text: "Rapport envoyé avec succès",
                        snackbar_type: "success",
                    });
                    this.dialog = false;

                    const go_back_data = routes[this.$route.name].appbar.go_back;
                    return this.$router.push({ name: go_back_data.page, params: go_back_data.params() });
                } else {
                    this.$store.dispatch("snackbar/show", {
                        snackbar_text: response.data.data.error,
                        snackbar_type: "error",
                    });
                }
            });
        },
        async globalData() {
            await new Promise((resolve) => setTimeout(resolve, 500));
            return {
                preventivePlan: {
                    hasPreventionPlan: this.preventivePlan.hasPreventionPlan,
                    viewPreventionPlan: this.preventivePlan.viewPreventionPlan,
                    presenceOfTheCustomer: this.preventivePlan.presenceOfTheCustomer,
                    providerSignature: this.provider_signature,
                    clientSignature: this.preventivePlan.clientSignature,
                },
                battery: this.manhole.lastBattery,
                lastEmisionDate: this.manhole.lastEmisionDate,
            };
        },
        formatData() {
            if (this.controlOfTheDistributor.cartridgeFilterControl.filterStock.inStock === undefined || this.controlOfTheDistributor.cartridgeFilterControl.filterStock.inStock == "") this.controlOfTheDistributor.cartridgeFilterControl.filterStock.inStock = 0;
            return [
                {
                    title: "Sécurité",
                    content: [
                        {
                            type: "checkbox",
                            label: "Echelle harnachée",
                            checked: this.security.hasScamLadder,
                        },
                        {
                            type: "checkbox",
                            label: "Baudrier avec ligne de vie",
                            checked: this.security.hasHarnessWithLifeLine,
                        },
                        {
                            type: "checkbox",
                            label: "EPI : Chaussures de sécurité, casque, lunettes, gants et vêtements à bandes réfléchissantes",
                            checked: this.security.hasPersonalProtectiveEquipment,
                        },
                    ],
                },
                {
                    title: "Vue générale",
                    content: [
                        this.globalView.tank !== null
                            ? {
                                  type: "image",
                                  legend: "Photo de la cuve",
                                  file: this.globalView.tank,
                              }
                            : {},
                        this.globalView.distributor !== null
                            ? {
                                  type: "image",
                                  legend: "Photo du distributeur",
                                  file: this.globalView.distributor,
                              }
                            : {},
                    ],
                },
                {
                    title: "Trou d’homme, détecteur de fuite, jaugeages électronique et manuel",
                    content: [
                        {
                            type: "paragraph",
                            text: "Dernier niveau de batterie : " + this.manhole.lastBattery + "% <i>(Donnée provenant de FUEL IT)</i>",
                        },
                        {
                            type: "checkbox",
                            label: "Changement de la batterie du capteur",
                            checked: this.manhole.changeBattery,
                        },
                        this.manhole.changeBattery ? {
                            type: "checkbox",
                            label: "Activer le capteur après changement de piles",
                            checked: this.manhole.activateSensorAfterChange,
                        }: {},
                        {
                            type: "paragraph",
                            text: "Nombre de relevés par jour: "+ this.manhole.device.nb_releve_par_jour,
                        },
                        this.manhole.changeDevice === true ?
                            {
                              type: "checkbox",
                              label: "Remplacer le capteur par un capteur 6 RJ",
                              checked: this.manhole.changeDevice,
                            } : {},
                        {
                            type: "divider",
                        },
                        {
                            type: "paragraph",
                            text: "Jaugeage manuel de la cuve avec mètre ruban : " + (this.manhole.lastMeasureCheck.value != -1 ? (this.manhole.lastMeasureCheck.value + "cm") : 'Non renseigné'),
                        },
                        {
                            type: "paragraph",
                            text: "Jaugeage FUEL IT : Hauteur de liquide : " + (this.manhole.lastMeasureCheck.expected + "cm <i>(Donnée provenant de FUEL IT)</i>"),
                        },
                        {
                            type: "paragraph",
                            text: this.manhole.lastMeasureCheck.valid ? "Niveau cohérent" : "Niveau incohérent",
                        },
                        {
                            type: "paragraph",
                            text: "Ai-je déjà corrigé l'offset au préalable ? " + (this.manhole.offsetCorrection.checked === "true" ? "Oui" : "Non"),
                        },
                        this.manhole.offsetCorrection.checked === "true" &&
                        this.manhole.offsetCorrection.gauging !== null
                            ? {
                                  type: "paragraph",
                                  text: "Correction: "+ this.manhole.offsetCorrection.gauging,
                              }
                            : {},
                        {
                            type: "divider",
                        },
                        {
                            type: "paragraph",
                            text: "Afficheur local : " + (this.manhole.localDisplayState.checked === "true" ? "OK" : "HS - Mal réglé"),
                        },
                        this.manhole.localDisplayState.checked === "true" &&
                        this.manhole.localDisplayState.height !== null ?
                            {
                                type: "paragraph",
                                text: "Hauteur renseignée: " + this.manhole.localDisplayState.height,
                            }: {},
                        this.manhole.localDisplayState.comment !== null && this.manhole.localDisplayState.comment != ""
                            ? {
                                  type: "comment",
                                  text: this.manhole.localDisplayState.comment,
                              }
                            : {},
                        this.manhole.localDisplayState.file !== null
                            ? {
                                  type: "image",
                                  file: this.manhole.localDisplayState.file,
                              }
                            : {},
                        {
                            type: "divider",
                        },
                        {
                            type: "checkbox",
                            label: "Contrôle du fonctionnement du détecteur de fuite et complément du réservoir de glycol (antigel).",
                            checked: this.manhole.controlOfTheLeakDetector,
                        },
                        this.manhole.controlOfTheLeakDetector
                            ? {
                                  type: "radiogroup",
                                  label: "Niveau",
                                  choices: [
                                      {
                                          type: "radio",
                                          label: "Conforme",
                                          checked: this.manhole.leakDetectorCompliant,
                                      },
                                      {
                                          type: "radio",
                                          label: "Non conforme",
                                          checked: !this.manhole.leakDetectorCompliant,
                                      },
                                  ],
                              }
                            : {},
                        this.manhole.controlOfTheLeakDetector && this.manhole.leakDetectorCompliant == "false"
                            ? {
                                  type: "radiogroup",
                                  label: "Besoin complément du produit Antigel",
                                  choices: [
                                      {
                                          type: "radio",
                                          label: "Oui",
                                          checked: this.manhole.needsComplementAntifreeze === "true",
                                      },
                                      {
                                          type: "radio",
                                          label: "Non",
                                          checked: this.manhole.needsComplementAntifreeze === "false",
                                      },
                                  ],
                              }
                            : {},
                        this.manhole.controlOfTheLeakDetector && this.manhole.leakDetectorCompliant == "false" && this.manhole.needsComplementAntifreeze == "true"
                            ? {
                                  type: "radiogroup",
                                  label: "Quantité réassort estimé",
                                  choices: [
                                      {
                                          type: "radio",
                                          label: "1L",
                                          checked: this.manhole.antifreezeVolume == "1",
                                      },
                                      {
                                          type: "radio",
                                          label: "2L",
                                          checked: this.manhole.antifreezeVolume == "2",
                                      },
                                      {
                                          type: "radio",
                                          label: "5L",
                                          checked: this.manhole.antifreezeVolume == "5",
                                      },
                                  ],
                              }
                            : {},
                        {
                            type: "checkbox",
                            label: "Trou d’homme, contrôle des soudures et joints",
                            checked: this.manhole.controlOfWeldsAndJoints.checked,
                        },
                        this.manhole.controlOfWeldsAndJoints.checked === true
                            ? {
                                  type: "radiogroup",
                                  label: "Conforme",
                                  choices: [
                                      {
                                          type: "radio",
                                          label: "Oui",
                                          checked: this.manhole.controlOfWeldsAndJoints.compliant === "true",
                                      },
                                      {
                                          type: "radio",
                                          label: "Non",
                                          checked: this.manhole.controlOfWeldsAndJoints.compliant === "false",
                                      },
                                  ],
                              }
                            : {},
                        this.manhole.controlOfWeldsAndJoints.checked === true && this.manhole.controlOfWeldsAndJoints.compliant === "false" && this.manhole.controlOfWeldsAndJoints.comment !== null && this.manhole.controlOfWeldsAndJoints.comment != ""
                            ? {
                                  type: "comment",
                                  text: this.manhole.controlOfWeldsAndJoints.comment,
                              }
                            : {},
                        this.manhole.controlOfWeldsAndJoints.checked === true && this.manhole.controlOfWeldsAndJoints.compliant === "false" && this.manhole.controlOfWeldsAndJoints.file !== null
                            ? {
                                  type: "image",
                                  file: this.manhole.controlOfWeldsAndJoints.file,
                              }
                            : {},
                    ],
                },
                {
                    title: "Contrôles visuels divers - Hors trou d’homme",
                    content: [
                        {
                            type: "checkbox",
                            label: "Vérification du sol, affaissement, fissures, déformation, inclinaison",
                            checked: this.variousVisualControls.floorChecks.checked,
                        },
                        this.variousVisualControls.floorChecks.checked === true
                            ? {
                                  type: "radiogroup",
                                  label: "Conforme",
                                  choices: [
                                      {
                                          type: "radio",
                                          label: "Oui",
                                          checked: this.variousVisualControls.floorChecks.compliant === "true",
                                      },
                                      {
                                          type: "radio",
                                          label: "Non",
                                          checked: this.variousVisualControls.floorChecks.compliant === "false",
                                      },
                                  ],
                              }
                            : {},
                        this.variousVisualControls.floorChecks.checked === true &&
                        this.variousVisualControls.floorChecks.compliant === "false" &&
                        this.variousVisualControls.floorChecks.comment !== null &&
                        this.variousVisualControls.floorChecks.comment != ""
                            ? {
                                  type: "comment",
                                  text: this.variousVisualControls.floorChecks.comment,
                              }
                            : {},
                        this.variousVisualControls.floorChecks.checked === true && this.variousVisualControls.floorChecks.compliant === "false" && this.variousVisualControls.floorChecks.file !== null
                            ? {
                                  type: "image",
                                  file: this.variousVisualControls.floorChecks.file,
                              }
                            : {},
                        {
                            type: "checkbox",
                            label: "Contrôle de l’état général de la cuve (défauts peinture, coulures, coups, rouille, usure)",
                            checked: this.variousVisualControls.tankConditions.checked,
                        },
                        this.variousVisualControls.tankConditions.checked === true
                            ? {
                                  type: "radiogroup",
                                  label: "Conforme",
                                  choices: [
                                      {
                                          type: "radio",
                                          label: "Oui",
                                          checked: this.variousVisualControls.tankConditions.compliant === "true",
                                      },
                                      {
                                          type: "radio",
                                          label: "Non",
                                          checked: this.variousVisualControls.tankConditions.compliant === "false",
                                      },
                                  ],
                              }
                            : {},
                        this.variousVisualControls.tankConditions.checked === true &&
                        this.variousVisualControls.tankConditions.compliant === "false" &&
                        this.variousVisualControls.tankConditions.comment !== null &&
                        this.variousVisualControls.tankConditions.comment != ""
                            ? {
                                  type: "comment",
                                  text: this.variousVisualControls.tankConditions.comment,
                              }
                            : {},
                        this.variousVisualControls.tankConditions.checked === true && this.variousVisualControls.tankConditions.compliant === "false" && this.variousVisualControls.tankConditions.file !== null
                            ? {
                                  type: "image",
                                  file: this.variousVisualControls.tankConditions.file,
                              }
                            : {},
                        {
                            type: "checkbox",
                            label: "Vérification de la tuyauterie visible",
                            checked: this.variousVisualControls.pipingChecking.checked,
                        },
                        this.variousVisualControls.pipingChecking.checked === true
                            ? {
                                  type: "radiogroup",
                                  label: "Conforme",
                                  choices: [
                                      {
                                          type: "radio",
                                          label: "Oui",
                                          checked: this.variousVisualControls.pipingChecking.compliant === "true",
                                      },
                                      {
                                          type: "radio",
                                          label: "Non",
                                          checked: this.variousVisualControls.pipingChecking.compliant === "false",
                                      },
                                  ],
                              }
                            : {},
                        this.variousVisualControls.pipingChecking.checked === true &&
                        this.variousVisualControls.pipingChecking.compliant === "false" &&
                        this.variousVisualControls.pipingChecking.comment !== null &&
                        this.variousVisualControls.pipingChecking.comment != ""
                            ? {
                                  type: "comment",
                                  text: this.variousVisualControls.pipingChecking.comment,
                              }
                            : {},
                        this.variousVisualControls.pipingChecking.checked === true && this.variousVisualControls.pipingChecking.compliant === "false" && this.variousVisualControls.pipingChecking.file !== null
                            ? {
                                  type: "image",
                                  file: this.variousVisualControls.pipingChecking.file,
                              }
                            : {},
                        {
                            type: "checkbox",
                            label: "Vérification de la présence des plaques réglementaires",
                            checked: this.variousVisualControls.regulatoryPlates.checked,
                        },
                        this.variousVisualControls.regulatoryPlates.checked === true
                            ? {
                                  type: "radiogroup",
                                  label: "Dépotage - identification cuve :",
                                  choices: [
                                      {
                                          type: "radio",
                                          label: "Oui",
                                          checked: this.variousVisualControls.regulatoryPlates.tippingIdentification === "true",
                                      },
                                      {
                                          type: "radio",
                                          label: "Non",
                                          checked: this.variousVisualControls.regulatoryPlates.tippingIdentification === "false",
                                      },
                                  ],
                              }
                            : {},
                        this.variousVisualControls.regulatoryPlates.checked === true
                            ? {
                                  type: "radiogroup",
                                  label: "QR Code télémétrie Four Data :",
                                  choices: [
                                      {
                                          type: "radio",
                                          label: "Oui",
                                          checked: this.variousVisualControls.regulatoryPlates.fourdataQRCode === "true",
                                      },
                                      {
                                          type: "radio",
                                          label: "Non",
                                          checked: this.variousVisualControls.regulatoryPlates.fourdataQRCode === "false",
                                      },
                                  ],
                              }
                            : {},
                        this.variousVisualControls.regulatoryPlates.pastPoster === true
                            ? {
                                type: "checkbox",
                                label: "Collage de l'affiche ainsi que le QR code dans le bon emplacement ",
                                checked: this.variousVisualControls.regulatoryPlates.pastPoster,
                            } : {},
                        this.variousVisualControls.regulatoryPlates.checked === true && this.variousVisualControls.regulatoryPlates.fourdataQRCode === "false"
                            ? {
                                  type: "paragraph",
                                  text: "<b>(Envoyez un email à <a href='mailto: servicecuve.oleo100@groupeavril.com'>servicecuve.oleo100@groupeavril.com</a>)</b>",
                              }
                            : {},
                    ],
                },
                {
                    title: "Contrôle armoire électrique",
                    content: [
                        {
                            type: "checkbox",
                            label: "Contrôle du bon déclenchement des protections des composants électriques",
                            checked: this.controlOfTheElectricalCabinet.controlTriggeringOfSafety.checked,
                        },
                        this.controlOfTheElectricalCabinet.controlTriggeringOfSafety.checked === true
                            ? {
                                  type: "radiogroup",
                                  label: "Différentiels",
                                  choices: [
                                      {
                                          type: "radio",
                                          label: "Oui",
                                          checked: this.controlOfTheElectricalCabinet.controlTriggeringOfSafety.differential === "true",
                                      },
                                      {
                                          type: "radio",
                                          label: "Non",
                                          checked: this.controlOfTheElectricalCabinet.controlTriggeringOfSafety.differential === "false",
                                      },
                                  ],
                              }
                            : {},
                        this.controlOfTheElectricalCabinet.controlTriggeringOfSafety.checked === true
                            ? {
                                  type: "radiogroup",
                                  label: "Arrêt d’urgence",
                                  choices: [
                                      {
                                          type: "radio",
                                          label: "Oui",
                                          checked: this.controlOfTheElectricalCabinet.controlTriggeringOfSafety.emergencyStop === "true",
                                      },
                                      {
                                          type: "radio",
                                          label: "Non",
                                          checked: this.controlOfTheElectricalCabinet.controlTriggeringOfSafety.emergencyStop === "false",
                                      },
                                  ],
                              }
                            : {},
                        this.controlOfTheElectricalCabinet.controlTriggeringOfSafety.checked === true &&
                        this.controlOfTheElectricalCabinet.controlTriggeringOfSafetyComment !== null &&
                        this.controlOfTheElectricalCabinet.controlTriggeringOfSafetyComment != ""
                            ? {
                                  type: "comment",
                                  text: this.controlOfTheElectricalCabinet.controlTriggeringOfSafetyComment,
                              }
                            : {},
                        this.controlOfTheElectricalCabinet.controlTriggeringOfSafety.checked === true && this.controlOfTheElectricalCabinet.controlTriggeringOfSafetyFile !== null
                            ? {
                                  type: "image",
                                  file: this.controlOfTheElectricalCabinet.controlTriggeringOfSafetyFile,
                              }
                            : {},
                        {
                            type: "checkbox",
                            label: "Vérification des équipements électriques (bonne fixation et oxydation notamment)",
                            checked: this.controlOfTheElectricalCabinet.verificationOfElectricalEquipment.checked,
                        },
                        this.controlOfTheElectricalCabinet.verificationOfElectricalEquipment.checked
                            ? {
                                  type: "radiogroup",
                                  label: "Conforme",
                                  choices: [
                                      {
                                          type: "radio",
                                          label: "Oui",
                                          checked: this.controlOfTheElectricalCabinet.verificationOfElectricalEquipment.compliant === "true",
                                      },
                                      {
                                          type: "radio",
                                          label: "Non",
                                          checked: this.controlOfTheElectricalCabinet.verificationOfElectricalEquipment.compliant === "false",
                                      },
                                  ],
                              }
                            : {},
                        this.controlOfTheElectricalCabinet.verificationOfElectricalEquipment.checked === true &&
                        this.controlOfTheElectricalCabinet.verificationOfElectricalEquipment.compliant === "false" &&
                        this.controlOfTheElectricalCabinet.verificationOfElectricalEquipment.comment !== null &&
                        this.controlOfTheElectricalCabinet.verificationOfElectricalEquipment.comment != ""
                            ? {
                                  type: "comment",
                                  text: this.controlOfTheElectricalCabinet.verificationOfElectricalEquipment.comment,
                              }
                            : {},
                        this.controlOfTheElectricalCabinet.verificationOfElectricalEquipment.checked === true &&
                        this.controlOfTheElectricalCabinet.verificationOfElectricalEquipment.compliant === "false" &&
                        this.controlOfTheElectricalCabinet.verificationOfElectricalEquipment.file !== null
                            ? {
                                  type: "image",
                                  file: this.controlOfTheElectricalCabinet.verificationOfElectricalEquipment.file,
                              }
                            : {},
                        {
                            type: "checkbox",
                            label: "Vérification de la détection de fuites - Bouton test, puis Bouton Arrêt Vibreur",
                            checked: this.controlOfTheElectricalCabinet.checkingForLeaks.checked,
                        },
                        this.controlOfTheElectricalCabinet.checkingForLeaks.checked === true
                            ? {
                                  type: "radiogroup",
                                  label: "Conforme",
                                  choices: [
                                      {
                                          type: "radio",
                                          label: "Oui",
                                          checked: this.controlOfTheElectricalCabinet.checkingForLeaks.compliant === "true",
                                      },
                                      {
                                          type: "radio",
                                          label: "Non",
                                          checked: this.controlOfTheElectricalCabinet.checkingForLeaks.compliant === "false",
                                      },
                                  ],
                              }
                            : {},
                        this.controlOfTheElectricalCabinet.checkingForLeaks.checked === true &&
                        this.controlOfTheElectricalCabinet.checkingForLeaks.compliant === "false" &&
                        this.controlOfTheElectricalCabinet.checkingForLeaks.comment !== null &&
                        this.controlOfTheElectricalCabinet.checkingForLeaks.comment != ""
                            ? {
                                  type: "comment",
                                  text: this.controlOfTheElectricalCabinet.checkingForLeaks.comment,
                              }
                            : {},
                        this.controlOfTheElectricalCabinet.checkingForLeaks.checked === true && this.controlOfTheElectricalCabinet.checkingForLeaks.compliant === "false" && this.controlOfTheElectricalCabinet.verificationOfElectricalEquipment.file !== null
                            ? {
                                  type: "image",
                                  file: this.controlOfTheElectricalCabinet.checkingForLeaks.file,
                              }
                            : {},
                        {
                            type: "checkbox",
                            label: "Contrôle visuel des câbles électriques, dépoussiérage, presse étoupes et boîtiers de raccordements",
                            checked: this.controlOfTheElectricalCabinet.electricalCableControl.checked,
                        },
                        this.controlOfTheElectricalCabinet.electricalCableControl.checked === true
                            ? {
                                  type: "radiogroup",
                                  label: "Conforme",
                                  choices: [
                                      {
                                          type: "radio",
                                          label: "Oui",
                                          checked: this.controlOfTheElectricalCabinet.electricalCableControl.compliant === "true",
                                      },
                                      {
                                          type: "radio",
                                          label: "Non",
                                          checked: this.controlOfTheElectricalCabinet.electricalCableControl.compliant === "false",
                                      },
                                  ],
                              }
                            : {},
                        this.controlOfTheElectricalCabinet.electricalCableControl.checked === true &&
                        this.controlOfTheElectricalCabinet.electricalCableControl.compliant === "false" &&
                        this.controlOfTheElectricalCabinet.electricalCableControl.comment !== null &&
                        this.controlOfTheElectricalCabinet.electricalCableControl.comment != ""
                            ? {
                                  type: "comment",
                                  text: this.controlOfTheElectricalCabinet.electricalCableControl.comment,
                              }
                            : {},
                        this.controlOfTheElectricalCabinet.electricalCableControl.checked === true && this.controlOfTheElectricalCabinet.electricalCableControl.compliant === "false" && this.controlOfTheElectricalCabinet.electricalCableControl.file !== null
                            ? {
                                  type: "image",
                                  file: this.controlOfTheElectricalCabinet.electricalCableControl.file,
                              }
                            : {},
                        {
                            type: "checkbox",
                            label: "Serrage des connections",
                            checked: this.controlOfTheElectricalCabinet.tighteningTheConnections.checked,
                        },
                        this.controlOfTheElectricalCabinet.tighteningTheConnections.checked === true
                            ? {
                                  type: "radiogroup",
                                  label: "Conforme",
                                  choices: [
                                      {
                                          type: "radio",
                                          label: "Oui",
                                          checked: this.controlOfTheElectricalCabinet.tighteningTheConnections.compliant === "true",
                                      },
                                      {
                                          type: "radio",
                                          label: "Non",
                                          checked: this.controlOfTheElectricalCabinet.tighteningTheConnections.compliant === "false",
                                      },
                                  ],
                              }
                            : {},
                        this.controlOfTheElectricalCabinet.tighteningTheConnections.checked === true &&
                        this.controlOfTheElectricalCabinet.tighteningTheConnections.compliant === "false" &&
                        this.controlOfTheElectricalCabinet.tighteningTheConnections.comment !== null &&
                        this.controlOfTheElectricalCabinet.tighteningTheConnections.comment != ""
                            ? {
                                  type: "comment",
                                  text: this.controlOfTheElectricalCabinet.tighteningTheConnections.comment,
                              }
                            : {},
                        this.controlOfTheElectricalCabinet.tighteningTheConnections.checked === true &&
                        this.controlOfTheElectricalCabinet.tighteningTheConnections.compliant === "false" &&
                        this.controlOfTheElectricalCabinet.tighteningTheConnections.file !== null
                            ? {
                                  type: "image",
                                  file: this.controlOfTheElectricalCabinet.tighteningTheConnections.file,
                              }
                            : {},
                    ],
                },
                {
                    title: "Contrôle distributeur",
                    content: [
                        {
                            type: "checkbox",
                            label: "Contrôle état pistolets",
                            checked: this.controlOfTheDistributor.gunConditionCheck.checked,
                        },
                        this.controlOfTheDistributor.gunConditionCheck.checked === true
                            ? {
                                  type: "radiogroup",
                                  label: "Conforme",
                                  choices: [
                                      {
                                          type: "radio",
                                          label: "Oui",
                                          checked: this.controlOfTheDistributor.gunConditionCheck.compliant === "true",
                                      },
                                      {
                                          type: "radio",
                                          label: "Non",
                                          checked: this.controlOfTheDistributor.gunConditionCheck.compliant === "false",
                                      },
                                  ],
                              }
                            : {},
                        this.controlOfTheDistributor.gunConditionCheck.checked === true &&
                        this.controlOfTheDistributor.gunConditionCheck.compliant === "false" &&
                        this.controlOfTheDistributor.gunConditionCheck.comment !== null &&
                        this.controlOfTheDistributor.gunConditionCheck.comment != ""
                            ? {
                                  type: "comment",
                                  text: this.controlOfTheDistributor.gunConditionCheck.comment,
                              }
                            : {},
                        this.controlOfTheDistributor.gunConditionCheck.checked === true && this.controlOfTheDistributor.gunConditionCheck.compliant === "false" && this.controlOfTheDistributor.gunConditionCheck.file !== null
                            ? {
                                  type: "image",
                                  file: this.controlOfTheDistributor.gunConditionCheck.file,
                              }
                            : {},
                        {
                            type: "checkbox",
                            label: "Contrôle état flexible avec date de validité (valable 6 ans), changement si nécessaire (Présence craquelures, trace de fuites, défaut d’étanchéité)",
                            checked: this.controlOfTheDistributor.flexibleCheck.checked,
                        },
                        this.controlOfTheDistributor.flexibleCheck.checked === true
                            ? {
                                  type: "radiogroup",
                                  label: "Conforme",
                                  choices: [
                                      {
                                          type: "radio",
                                          label: "Oui",
                                          checked: this.controlOfTheDistributor.flexibleCheck.compliant === "true",
                                      },
                                      {
                                          type: "radio",
                                          label: "Non",
                                          checked: this.controlOfTheDistributor.flexibleCheck.compliant === "false",
                                      },
                                  ],
                              }
                            : {},
                        this.controlOfTheDistributor.flexibleCheck.checked === true &&
                        this.controlOfTheDistributor.flexibleCheck.compliant === "false" &&
                        this.controlOfTheDistributor.flexibleCheck.comment !== null &&
                        this.controlOfTheDistributor.flexibleCheck.comment != ""
                            ? {
                                  type: "comment",
                                  text: this.controlOfTheDistributor.flexibleCheck.comment,
                              }
                            : {},
                        this.controlOfTheDistributor.flexibleCheck.checked === true &&
                        this.controlOfTheDistributor.flexibleCheck.compliant === "false" &&
                        this.controlOfTheDistributor.flexibleCheck.validityDate !== ""
                            ? {
                                type: "paragraph",
                                text: "Date de validité: "+this.controlOfTheDistributor.flexibleCheck.validityDate,
                            }
                            : {},
                        this.controlOfTheDistributor.flexibleCheck.checked === true && this.controlOfTheDistributor.flexibleCheck.compliant === "false" && this.controlOfTheDistributor.flexibleCheck.file !== null
                            ? {
                                  type: "image",
                                  file: this.controlOfTheDistributor.flexibleCheck.file,
                              }
                            : {},
                        {
                            type: "checkbox",
                            label: "Remplacement du filtre existant  par un EPZ75 100 microns, si le filtre en place est conforme, le nettoyer.",
                            checked: this.controlOfTheDistributor.filterReplacement,
                        },
                        {
                            type: "divider",
                        },
                        {
                            type: "checkbox",
                            label: "Contrôle du filtre à cartouche du distributeur",
                            checked: this.controlOfTheDistributor.cartridgeFilterControl.checked,
                        },
                        this.controlOfTheDistributor.cartridgeFilterControl.checked === true
                            ? {
                                type: "radiogroup",
                                label: "Filtre à cartouche présent :",
                                choices: [
                                    {
                                        type: "radio",
                                        label: "Oui",
                                        checked: this.controlOfTheDistributor.cartridgeFilterControl.cartridgeFilter.checked === "true",
                                    },
                                    {
                                        type: "radio",
                                        label: "Non",
                                        checked: this.controlOfTheDistributor.cartridgeFilterControl.cartridgeFilter.checked === "false",
                                    },
                                ],
                            } : {},
                        this.controlOfTheDistributor.cartridgeFilterControl.cartridgeFilter.checked === "false" &&
                        this.controlOfTheDistributor.cartridgeFilterControl.cartridgeFilter.comment !== ""
                            ? {
                                type: "comment",
                                text: this.controlOfTheDistributor.cartridgeFilterControl.cartridgeFilter.comment,
                            } : {},
                        this.controlOfTheDistributor.cartridgeFilterControl.checked === true &&
                        this.controlOfTheDistributor.cartridgeFilterControl.cartridgeFilter.file !== null
                            ? {
                                type: "image",
                                file: this.controlOfTheDistributor.cartridgeFilterControl.cartridgeFilter.file ,
                            }
                            : {},
                      {
                        type: "checkbox",
                        label: "Vérification stock de filtres additionnels chez le client si possible",
                        checked: this.controlOfTheDistributor.cartridgeFilterControl.filterStock.checked,
                      },
                      this.controlOfTheDistributor.cartridgeFilterControl.filterStock.checked === true
                          ? {
                            type: "radiogroup",
                            label: " ",
                            choices: [
                              {
                                type: "radio",
                                label: "Existants",
                                checked: this.controlOfTheDistributor.cartridgeFilterControl.filterStock.hasFilterStock === "true",
                              },
                              {
                                type: "radio",
                                label: "Absents",
                                checked: this.controlOfTheDistributor.cartridgeFilterControl.filterStock.hasFilterStock === "false",
                              },
                            ],
                          }
                          : {},
                      this.controlOfTheDistributor.cartridgeFilterControl.filterStock.checked === true && this.controlOfTheDistributor.cartridgeFilterControl.filterStock.hasFilterStock === "true"
                        ? {
                          type: "paragraph",
                          text: "Le client a " + this.controlOfTheDistributor.cartridgeFilterControl.filterStock.inStock + " filtre(s).",
                        }
                        : {},
                        {
                            type: "checkbox",
                            label: "Contrôle de la bonne ouverture du bypass du filtre sur le distributeur",
                            checked: this.controlOfTheDistributor.filterBypassOpeningControl,
                        },
                        {
                          type: "paragraph",
                          text: "La vanne de bypass est-elle bien plombée: " + (this.controlOfTheDistributor.isValveSealed === "true" ? "Oui" : "Non"),
                        },
                      this.controlOfTheDistributor.valvePicture !== null
                        ? {
                          type: "image",
                          file: this.controlOfTheDistributor.valvePicture ,
                        }
                        : {},
                        {
                            type: "paragraph",
                            text: "Contrôle de la tension de la courroie de groupe",
                        },
                        {
                            type: "checkbox",
                            label: "Couper l’alimentation moteur (disjoncteur général)",
                            checked: this.controlOfTheDistributor.switchOffMotor,
                        },
                        {
                            type: "checkbox",
                            label: "Contrôle de la tension",
                            checked: this.controlOfTheDistributor.tensionControl,
                        },
                        this.controlOfTheDistributor.tensionControl === true
                            ? {
                                  type: "radiogroup",
                                  label: "Reprise de la tension :",
                                  choices: [
                                      {
                                          type: "radio",
                                          label: "Oui",
                                          checked: this.controlOfTheDistributor.resumptionOfTension === "true",
                                      },
                                      {
                                          type: "radio",
                                          label: "Non",
                                          checked: this.controlOfTheDistributor.resumptionOfTension === "false",
                                      },
                                  ],
                              }
                            : {},
                        this.controlOfTheDistributor.tensionControl === true
                            ? {
                                  type: "radiogroup",
                                  label: "Changement de la courroie :",
                                  choices: [
                                      {
                                          type: "radio",
                                          label: "Oui",
                                          checked: this.controlOfTheDistributor.changingBelt === "true",
                                      },
                                      {
                                          type: "radio",
                                          label: "Non",
                                          checked: this.controlOfTheDistributor.changingBelt === "false",
                                      },
                                  ],
                              }
                            : {},
                        {
                            type: "checkbox",
                            label: "Nettoyage des éléments de carrosseries distributeur",
                            checked: this.controlOfTheDistributor.cleaning,
                        },
                    ],
                },
            ];
        },
    },
    mounted() {
        let maintenance = this.$store.getters["maintenance/get"]
        this.preventivePlan.comment = maintenance.comment
        this.manhole.device = maintenance.device
    },
};
</script>

<style scoped>
#oleo100-curative-form {
    width: 100%;
    max-width: 500px;
}
</style>